<template>
  <div v-if="commitList">
    <van-collapse
      v-model="activeNames"
      v-for="(item, index) in commitList.targetEntityList"
      :key="index"
    >
      <van-collapse-item :title="item.target" :name="item.activeName">
        <div
          class="content"
          v-for="(item2, index2) in item.drugsEntityList"
          :key="index2"
        >
          <div class="leftCon">
            <div class="leftCon-title">靶向药名称</div>
            <div class="leftCon-con">{{ item2.geneDrugs }}</div>
          </div>
          <div class="borders">
            <van-row>
              <van-col span="8">
                <div class="title">
                  <div class="titleName">商品名</div>
                  <div class="titleCon">{{ item2.goodsName }}</div>
                </div>
              </van-col>
              <van-col span="8">
                <div class="title">
                  <div class="titleName">纳入医保</div>
                  <div class="titleCon">
                    <img
                      v-if="item2.insurance === '是'"
                      src="../../../assets/true.png"
                    />
                    <img
                      v-if="item2.insurance === '否'"
                      src="../../../assets/false.png"
                    />
                  </div></div
              ></van-col>
              <van-col span="8">
                <div class="title">
                  <div class="titleName">国内上市</div>
                  <div class="titleCon">
                    <img
                      v-if="item2.isList === '是'"
                      src="../../../assets/true.png"
                    />
                    <img
                      v-if="item2.isList === '否'"
                      src="../../../assets/false.png"
                    />
                  </div></div
              ></van-col>
            </van-row>
            <van-row>
              <van-col span="8">
                <div class="title">
                  <div class="titleName">厂家名称</div>
                  <div class="titleCon">{{ item2.manufactor }}</div>
                </div></van-col
              >
              <van-col span="8">
                <div class="title">
                  <div class="titleName">价格参考</div>
                  <div class="titleCon">{{ item2.price }}</div>
                </div></van-col
              >
              <van-col span="8">
                <div class="title" style="height: 35px; background: #f6f6f6">
                  <div></div>
                  <div></div>
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: [1],
      commitList: [],
      allActiveNames: [],
    };
  },
  props: {
    num: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    num() {
      this.activeNames = this.allActiveNames;

      // this.activeNames.push(this.commitList)
    },
  },
  methods: {},
  mounted() {
    this.commitList = this.$store.getters.commitList;
    this.activeNames[0] = this.commitList?.targetEntityList[0].activeName;
    this.commitList?.targetEntityList.forEach((el) => {
      this.allActiveNames.push(el.activeName);
    });

    if (!this.commitList) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-cell {
  text-align: left;
  background: #0067f2;
  color: #fff;
}

::v-deep .van-cell__right-icon {
  color: #fff;
}

::v-deep .van-collapse-item__content {
  padding: 0;
}

::v-deep .van-cell__title {
  font-weight: bold;
}

.content {
  display: flex;
  margin-bottom: 10px;
  .borders {
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  .title {
    width: 100px;
    font-size: 14px;
    .titleName {
      background: #f6f6f6;
      height: 35px;
      line-height: 35px;
      color: #666;
    }
    .titleCon {
      padding: 12px 7px;
      color: #333;
      word-wrap: break-word;
    }

    img {
      width: 17px;
      height: 17px;
      line-height: 17px;
    }
  }
  .leftCon {
    width: 24vw;
    // height: 21.334vw;
    border: 1px solid #dddddd;
    .leftCon-title {
      background: #c2daff;
      height: 35px;
      line-height: 35px;
      color: #666;
    }

    .leftCon-con {
      color: #333;
      word-wrap: break-word;
      padding: 5px;
    }
  }
}
</style>
