<template>
  <div class="phone">
    <div class="phoneBackground"></div>
    <div class="phoneCon">
      <div class="phoneInfo">
        所有信息均来源于国内权威网站。仅作参考，具体建议请咨询您的主治医生。
      </div>
      <input
        v-if="showBtns"
        placeholder="请输入手机号"
        maxlength="11"
        type="number"
        v-model.trim="phoneNumber"
      />
      <div
        style="
          display: flex;
          justify-content: center;
          padding-top: 25px;
          margin-bottom: 25px;
        "
      >
        <div class="phone-button" @click="savePhone()">提交并保存图片</div>
      </div>
      <div class="phoneAdvice">
        <div @click="beginCheck" style="margin-right: 14px">重新检测</div>
        <div v-if="showWeuiImg === true" @click="openShare" class="shareLink">
          分享链接
        </div>
        <div v-if="showChromeImg" class="shareLink" @click="shareCopyLink">
          分享复制链接
        </div>
        <div @click="openDialog" style="margin-left: 14px">留下建议</div>
      </div>

      <img
        class="weuiImg"
        v-if="share === true && showWeuiImg === true"
        src="../../../assets/share.png"
      />

      <van-dialog
        v-model="show"
        title="留下建议"
        show-cancel-button
        @confirm="confirm()"
        @cancel="concel()"
        :before-close="onBeforeClose"
      >
        <div class="inputs">
          <textarea
            maxlength="200"
            rows="8"
            placeholder="请告诉我们您的建议，以便我们更好的为您提供服务"
            v-model="content"
          ></textarea>
          <p>{{ content.length }}/200</p>
        </div>
      </van-dialog>
    </div>
    <share />
  </div>
</template>
<script>
import Share from "../../../components/wechat/share.vue";
export default {
  data() {
    return {
      share: false,
      show: false,
      content: "",
      phoneNumber: "",
      showWeuiImg: false,
      showChromeImg: false,
      showBtns: true,
    };
  },
  components: { Share },
  methods: {
    //分享调取接口
    addShare() {
      this.$http.post("/addShare").then((res) => {
        console.log(res);
      });
    },
    // 复制链接
    shareCopyLink() {
      const inputTag = document.createElement("input");
      document.body.appendChild(inputTag);
      // let url = window.location.origin + '/?source=7#/'
      let url = "http://192.168.2.101:9780/?source=7#/";
      // let url = 'http://192.168.2.101:9780/#/?source=7'
      console.log(url);
      inputTag.setAttribute("value", url);
      inputTag.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$toast({
          type: "success",
          message: "链接已复制到剪切板",
        });
      }
      document.body.removeChild(inputTag);
      this.addShare();
    },
    beginCheck() {
      console.log(
        "back",
        this.$store.getters.beginSource,
        this.$store.getters.relation
      );

      this.$router.go(-1);
      // this.$router.push({
      //   name: 'Login',
      //   query: {
      //     relation: this.$store.getters.relation,
      //     source: this.$store.getters.source,
      //   },
      // })
    },
    openShare() {
      this.share = !this.share;
      this.addShare();
    },
    openDialog() {
      this.show = !this.show;
    },
    confirm() {
      if (this.content) {
        this.$http
          .post("/commitRemark", {
            remark: this.content,
            uid: this.$store.getters.uid,
          })
          .then((res) => {
            console.log(res);
            if (res.code === 0) {
              this.content = "";
            }
          });
      }
    },
    onBeforeClose(action, done) {
      if (action === "confirm") {
        if (!this.content) {
          this.$toast("请填写建议");
          return done(false);
        } else {
          return done();
        }
      } else {
        return done();
      }
    },
    concel() {
      this.content = "";
    },
    savePhone() {
      if (this.phoneNumber.length === 0) {
        this.$toast("请填写手机号");
      }

      if (this.phoneNumber.length < 11) {
        this.$toast("请正确填写手机号");
      }

      if (this.phoneNumber.length === 11) {
        this.$http
          .post("/commitPhone", {
            phone: this.phoneNumber,
            uid: this.$store.getters.uid,
          })
          .then((res) => {
            console.log(res);

            if (res.code === 0) {
              this.$emit("handlerImg");
              this.content = "";
            }
          });
      }
    },
    isWeixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("微信浏览器");
        this.showWeuiImg = true;
      } else {
        if (
          window.askdrAPI ||
          window.webkit?.savePicture ||
          window.webkit?.messageHandlers?.savePicture
        ) {
          this.showBtns = false;
        } else {
          this.showChromeImg = true;
        }
      }
    },
  },
  mounted() {
    let phone = this.$store.getters.phone;
    if (phone) {
      this.phoneNumber = phone;
    }
    this.isWeixin();
    console.log(this.$store.getters.beginSource);
  },
};
</script>
<style lang="scss" scoped>
.phone {
  .phoneBackground {
    height: 20px;
    background: #f6f6f6;
  }
  .phoneCon {
    background-color: #ffffff;
    padding: 0 15px;
    .phoneInfo {
      text-align: left;
      color: #999;
      font-size: 14px;
      padding: 35px 0px 20px 0px;
    }
    input {
      width: 335px;
      height: 45px;
      border: none;
      background: #f6f6f6;
      text-indent: 10px;
      //padding-left: 10px;
    }

    input::-webkit-input-placeholder {
      color: #aaa;
      font-size: 17px;
    }

    .phone-button {
      width: 295px;
      height: 45px;
      line-height: 45px;
      background-image: linear-gradient(to right, #4f74ff, #0067f2);
      border-radius: 45px;
      color: #fff;
      font-size: 17px;
    }

    .phoneAdvice {
      display: flex;
      justify-content: center;
      div {
        width: 100px;
        height: 45px;
        line-height: 45px;
        border: 1px solid #4f74ff;
        color: #4f74ff;
        border-radius: 45px;
        margin-bottom: 20px;
      }
      .shareLink {
        padding: 0px 5px;
      }
    }

    .weuiImg {
      width: 255px;
      position: fixed;
      right: 1vw;
      top: 0;
    }

    .chromeImg {
      width: 255px;
      position: fixed;
      right: 0;
      bottom: 0;
    }

    .inputs {
      textarea {
        margin: 15px 0px 0px 0px;
        width: 86%;
        padding: 2.667vw;
        border: none;
        background: #f6f6f6;
        resize: none;
        border-radius: 5px;
      }
      p {
        margin: 5px 0px 15px 0px;
        text-align: right;
        padding-right: 15px;
        color: #ccc;
      }
    }
  }
}

::v-deep .van-button {
  color: #999;
}

::v-deep .van-dialog__confirm,
.van-dialog__confirm:active {
  color: #4f74ff;
}
</style>
