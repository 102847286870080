<template>
  <div>
    <div id="imageWrapper" style="background-color: #ffffff">
      <List :num="num" />
      <div v-if="showImg" class="imgUrl">@易问医APP</div>
    </div>
    <Phone @handlerImg="handlerImg" />

    <van-dialog
      v-model="showImg"
      title="长按图片保存"
      show-cancel-button
      :show-confirm-button="false"
      cancel-button-text="关闭弹窗"
      cancel-button-color="#4f74ff"
    >
      <div class="imgSize">
        <img :src="imgURL" width="100%" />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import List from "./blocks/list.vue";
import Phone from "./blocks/phone.vue";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      showImg: false,
      num: 0,
      imgURL: "",
    };
  },
  components: {
    List,
    Phone,
  },
  methods: {
    handlerImg() {
      if (
        window.askdrAPI ||
        window.webkit?.savePicture ||
        window.webkit?.messageHandlers?.savePicture
      ) {
        this.showImg = false;
      } else {
        this.showImg = true;
      }
      this.num++;
      const imageWrapper = document.getElementById("imageWrapper");

      setTimeout(() => {
        html2canvas(imageWrapper).then((canvas) => {
          let dataURL = canvas.toDataURL("image/png");
          this.imgURL = dataURL;
          if (dataURL !== "") {
            if (window.askdrAPI) {
              window.askdrAPI.savePicture(dataURL);
            } else if (window.webkit?.messageHandlers?.savePicture) {
              window.webkit.messageHandlers.savePicture.postMessage(dataURL);
            } else {
              this.$store.commit("setDownloadImg", dataURL);
            }
            // console.log(this.$store.getters.downloadImg)
          }
        });
      }, 3e2);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.imgUrl {
  font-size: 15px;
  color: #4f74ff;
  margin: 10px 0px 25px 0px;
}

.imgSize {
  width: 100%;
  height: 120vw;
  overflow: auto;
  margin-top: 20px;
}
</style>
